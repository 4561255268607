import React, { ReactElement } from 'react'

const External = (): ReactElement => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 70C54.7777 70 70 54.7777 70 36C70 17.2223 54.7777 2 36 2C17.2223 2 2 17.2223 2 36C2 54.7777 17.2223 70 36 70ZM36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8284 32.7574L31.7574 32.7574L31.7574 30.7574H41.2427V40.2426H39.2427V34.1716L32.4645 40.9497L31.0503 39.5355L37.8284 32.7574Z"
        fill="#fff"
      />
    </svg>
  )
}

export default External
