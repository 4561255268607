import { Components } from '@mui/material/styles'
import FontSize from '@config/theme/definitions/fontSize'

export default function SvgIconOverrides(): Components['MuiSvgIcon'] {
  return {
    styleOverrides: {
      root: {
        verticalAlign: 'middle',
        fontSize: FontSize['iconDefault'],
      },
      fontSizeSmall: {
        fontSize: FontSize['iconSmall'],
      },
      fontSizeLarge: {
        fontSize: FontSize['iconLarge'],
      },
    },
  }
}
