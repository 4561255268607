import de from './de.json'
import en from './en.json'

const messages: Record<string, Record<string, string>> = { de, en }
const defaultLang = 'de'

// change back to "en" when we have an english version for non-german users
const fallbackLang = 'de'

const availableLang = ['de']
//const availableLang = ['de', 'en']

export { messages, defaultLang, fallbackLang, availableLang }
