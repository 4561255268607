import { Theme, Components } from '@mui/material'
import FontFamily from '../definitions/fontFamily'

export default function BaselineOverrides(
  theme: Theme
): Components['MuiCssBaseline'] {
  return {
    styleOverrides: `
      html {
        ${FontFamily.join('')}
        a {
          color: inherit;
          text-decoration: none;
          transition-property: all;
          transition-duration: ${theme.transitions.duration.standard}ms;
          transition-timing-function: ${theme.transitions.easing.easeInOut};
        },
        sup {
          font-size: 0.6em;
          line-height: 0.75;
        },
      },
    `,
  }
}
