const FontSize = {
  xs: '12px', // infotext
  sm: '14px', // copy teaser
  base: '16px', // copy desk, tag not stage
  baseXS: '16px', // copy mobile
  lg: '18px', // h5 mobile/ desk, tag stage
  '2lg': '20px', // h4 mobile, intro mobile
  '3lg': '22px', // zitat mobile
  xl: '24px', // h3 mobile, h4 desk
  '2xl': '26px', // zitat desk
  '3xl': '28px', // h2 mobile, h3 desk, intro desk
  '4xl': '40px', // h1 mobile
  '5xl': '36px', // h2 desk
  '6xl': '56px', // h2bg desk
  '7xl': '80px', // h1 desk
  '8xl': '120px', // slider background small
  '9xl': '200px', // slider background large
  iconSmall: '16px', // icon small
  iconDefault: '24px', // icon default
  iconLarge: '32px', // icon large
}

export default FontSize
