import React, { ReactElement, ReactNode } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Icon, { IconProps } from '@components/core/icon'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
}

const styles = ({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    '& > span': {
      position: 'relative',
      display: 'inline-block',
      '&::after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '0',
        height: '1px',
        bottom: '0',
        left: '0',
        backgroundColor: 'currentColor',
        transition: 'width 0.3s ease',
      },
    },
    '@media (hover: hover)': {
      '&:hover': {
        '& > span': {
          '&::after': {
            width: '100%',
          },
        },
      },
    },
  },

  [`&.${classes.active}`]: {
    '& > span': {
      '&::after': {
        width: '100%',
      },
    },
  },

  [`& .${classes.icon}`]: {
    marginLeft: theme.spacing(2),
    verticalAlign: 'middle',
  },
})

const StyledGatsbyLink = styled(GatsbyLink)(styles)
const StyledLink = styled('a')(styles)

export type LinkProps = {
  children?: ReactNode
  className?: string
  to: string
  isExternal?: boolean | false
  isActive?: boolean | false
  icon?: IconProps
}

export default function Link({
  className,
  children,
  to,
  isExternal,
  isActive,
  icon,
  ...props
}: LinkProps): ReactElement {
  const { setCursorType } = useCustomCursor()

  if (to === '#usercentrics-revoke') {
    return (
      <StyledLink
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        onClick={() => window.UC_UI.showSecondLayer()}
      >
        <span>{children}</span>
      </StyledLink>
    )
  } else if (isExternal) {
    return (
      <StyledLink
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        href={to}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setCursorType('link')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
        {icon && (
          <Icon
            name={icon.name}
            size={icon.size}
            className={clsx(icon.className, classes.icon)}
            color={icon.color}
          />
        )}
      </StyledLink>
    )
  } else {
    return (
      <StyledGatsbyLink
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        to={to}
        onMouseEnter={() => setCursorType('link')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
        {icon && (
          <Icon
            name={icon.name}
            size={icon.size}
            className={clsx(icon.className, classes.icon)}
            color={icon.color}
          />
        )}
      </StyledGatsbyLink>
    )
  }
}
