import React, { ReactElement } from 'react'

import { styled } from '@mui/material/styles'

import ArrowLeft from './icons/ArrowLeft'
import ArrowRight from './icons/ArrowRight'
import ArrowDown from './icons/ArrowDown'
import Play from './icons/Play'
import Pause from './icons/Pause'
import More from './icons/More'
import External from './icons/External'

import clsx from 'clsx'
import { GlobalStyles } from '@mui/material'
const PREFIX = 'index'

const classes = {
  cursor: `${PREFIX}-cursor`,
  focus: `${PREFIX}-focus`,
}

const Root = styled('div')(() => ({
  [`&.${classes.cursor}`]: {
    position: 'fixed',
    width: '24px',
    height: '24px',
    backgroundColor: '#FF2001',
    border: '2px solid #FF2001',
    boxSizing: 'border-box',
    borderRadius: '100%',
    transform: 'translate(calc(var(--x) - 50%), calc(var(--y) - 50%))',
    top: 0,
    left: 0,
    transition: 'width 0.3s ease-out, height 0.3s ease-out',
    willChange: 'width, height, transform, border',
    zIndex: 999999,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.hide': {
      width: '0px',
      height: '0px',
      backgroundColor: '#FF2001',
      border: '0px solid #FF2001',
    },
    '&.link': {
      width: '48px',
      height: '48px',
      backgroundColor: '#FF2001',
      border: '2px solid #FF2001',
    },
    '&.teaserMore': {
      width: '72px',
      height: '72px',
      backgroundColor: '#FF2001',
      border: '2px solid #FF2001',
      color: '#000021',
    },
    '&.teaserExternal': {
      width: '72px',
      height: '72px',
      backgroundColor: '#FF2001',
      border: '2px solid #FF2001',
      color: '#000021',
      '& svg': {
        display: 'block',
        transformOrigin: 'center center',
      },
    },
    '&.arrow-left': {
      width: '72px',
      height: '72px',
      borderColor: '#FF200100',
      backgroundColor: '#FF200100',
    },

    '&.arrow-right': {
      width: '72px',
      height: '72px',
      borderColor: '#FF200100',
      backgroundColor: '#FF200100',
    },

    '&.arrow-down': {
      width: '72px',
      height: '72px',
      borderColor: '#FF200100',
      backgroundColor: '#FF200100',
    },

    '&.play': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace',
      backgroundColor: '#29ace',
    },
    '&.play-pause': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace',
      backgroundColor: '#29ace',
    },
  },

  [`& .${classes.focus}`]: {
    opacity: 1,
  },
}))

export type CustomCursorProps = {
  type: string
}

const globalStyles = (
  <GlobalStyles
    styles={() => ({
      ':root': {
        '*': {
          cursor: 'none',
        },
      },
    })}
  />
)

export default function CustomCursor({
  type,
}: CustomCursorProps): ReactElement {
  const getIcon = (icon: string) => {
    switch (icon) {
      case 'arrow-left':
        return <ArrowLeft />
      case 'arrow-right':
        return <ArrowRight />
      case 'arrow-down':
        return <ArrowDown />
      case 'play':
        return <Play />
      case 'play-pause':
        return <Pause />
      case 'teaserExternal':
        return <External />
      case 'teaserMore':
        return <More />
    }
    return <span />
  }
  return (
    <Root className={clsx(classes.cursor, type)}>
      {getIcon(type)}
      {globalStyles}
    </Root>
  )
}
