import { Theme, Components } from '@mui/material/styles'
import BaselineOverrides from './overrides/baseline'
import SvgIconOverrides from './overrides/svgicons'
import RichtextEditorOverrides from './overrides/richtexteditor'

export default function OverridesFunction(theme: Theme): Components {
  return {
    MuiCssBaseline: BaselineOverrides(theme),
    MuiSvgIcon: SvgIconOverrides(),
    MUIRichTextEditor: RichtextEditorOverrides(theme),
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: theme.palette.text.secondary,
          },
          '& .MuiInput-underline::after': {
            borderBottomColor: theme.palette.error.light,
          },
          '& .MuiButton-root': {
            minWidth: theme.spacing(8),
            background: theme.palette.button.secondary,
            borderRadius: '50%',
            padding: theme.spacing(1.5),
            margin: theme.spacing(2, 1, 1, 1),
            '&:hover': {
              background: theme.palette.button.secondaryHover,
            },
          },
          '& .MuiMenu-paper': {
            minWidth: '282px !important',
            borderRadius: 0,
            marginLeft: '0px',
            marginTop: '12px',
            background: '#ff0000',
            '& .MuiList-padding': {
              padding: 0,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'inherit',
        },
        root: {
          color: 'inherit',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          position: 'static',
          width: '100%',
          maxWidth: theme.container.lg,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: theme.spacing(4),
          borderRadius: 0,
          [theme.breakpoints.up('md')]: {
            borderRight: `${theme.spacing(4)} solid ${
              theme.palette.button.secondary
            }`,
            borderRadius: theme.spacing(0, 2, 2, 0),
          },
        },
        paperWidthSm: {
          maxWidth: '650px',
        },
        paperWidthMd: {
          maxWidth: '704px',
          border: 0,
          borderRadius: 0,
        },
        paperWidthLg: {
          maxWidth: '809px',
        },
        paperFullScreen: {
          border: 'none',
        },
        paperFullWidth: {
          width: `calc(100% - ${theme.spacing(8)})`,
        },
        paperScrollPaper: {
          maxHeight: `calc(100% - ${theme.spacing(8)})`,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.main,
          color: theme.palette.text.primary,
          padding: theme.spacing(3, 16, 3, 4),
          borderRadius: theme.spacing(0, 4, 4, 0),
          borderRight: `${theme.spacing(4)} solid ${theme.palette.error.main}`,
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        message: {
          padding: 0,
          marginBottom: theme.spacing(1),
        },
        action: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {},
        ul: {
          justifyContent: 'center',

          '& > li:first-child': {
            '& .MuiPaginationItem-root': {
              height: '46px',
              width: '46px',
              borderRadius: '4px!important',
              backgroundColor: theme.palette.background.accent,
              [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(6),
              },
            },
          },
          '& > li:last-child': {
            '& .MuiPaginationItem-root': {
              height: '46px',
              width: '46px',
              borderRadius: '4px!important',
              backgroundColor: theme.palette.background.accent,
              [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(6),
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: '28px',
          width: '28px',
          minWidth: 0,
          borderRadius: '16px!important',
        },
        page: {},
        sizeSmall: {},
        outlined: {},
        rounded: {},
        ellipsis: {
          color: theme.palette.text.hint,
          fontSize: '30px',
          bottom: '7px',
          position: 'relative',
          right: '5px',
        },
        focusVisible: {},
        disabled: {
          visibility: 'hidden',
        },
        selected: {
          backgroundColor: `${theme.palette.background.greyDark}!important`,
          color: `${theme.palette.text.invert}!important`,
        },
        icon: {},
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {},
        checked: {
          color: theme.palette.primary.main,
          '& input + div > svg:first-child': {
            color: theme.palette.text.primary,
          },
        },
        disabled: {
          color: theme.palette.text.disabled,
        },
        colorPrimary: {
          color: theme.palette.text.primary,
        },
        colorSecondary: {
          color: theme.palette.text.primary,

          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  }
}
