import { Shadows } from '@mui/material/styles';

const CustomShadows: Shadows = [
  'none',
  '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)', // topicteaser
  '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  '0px 0px 10px 0px rgba(75, 75, 75, 0.18)',
  '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
  'string',
]

export default CustomShadows
