import React, { ReactElement, ReactNode } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Trunc from 'truncate-html'
import parse from 'html-react-parser'
import clsx from 'clsx'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('p')(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: 0,
    marginBottom: theme.spacing(8),
    hyphens: 'auto',
  },
}))

export type ParagraphProps = DBN.IReactDefaultProps & {
  truncate?: number
  truncateextend?: ReactNode
}

export default function Paragraph({
  className,
  children,
  truncate,
  truncateextend,
}: ParagraphProps): ReactElement {
  function TruncateOrNot(text: typeof React.Children | unknown) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    }
    if (typeof text == 'string')
      return parse(
        text
          .replaceAll('  ', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>'),
      )
    return text
  }

  return (
    <Root className={clsx(className, classes.root)} theme={useTheme()}>
      {!!children && TruncateOrNot(children)}
      {truncateextend}
    </Root>
  )
}
