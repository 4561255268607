import React, { ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import Icon, { IconProps } from '@components/core/icon'
import useCustomCursor from '@system/hooks/useCustomCursor'

const PREFIX = 'index'

const classes = {
  socialIcon: `${PREFIX}-socialIcon`,
  socialSvg: `${PREFIX}-socialSvg`,
}

const styles = ({ theme }: { theme: Theme }) => ({
  [`&.${classes.socialIcon}`]: {
    position: 'relative',
    display: 'inline-block',
    border: 0,
    outline: 0,
    background: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#FF2001',
        borderRadius: '50%',
        margin: '-24px',
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,
        '&::before': {
          transform: 'scale(1)',
        },
      },
    },
  },

  [`& .${classes.socialSvg}`]: {
    position: 'relative',
  },
})

const StyledLink = styled('a')(styles)
const StyledButton = styled('button')(styles)

export type SocialIconProps = DBN.IReactDefaultProps & {
  item: {
    name: string
    size?: IconProps['size']
    className?: string
    href?: string
    onClick?: () => void
  }
  title?: string
}

export default function SocialIcon({
  className,
  item,
  title,
}: SocialIconProps): ReactElement {
  const { setCursorType } = useCustomCursor()

  return item.href ? (
    <StyledLink
      href={item.href}
      rel="noopener noreferrer"
      target="_blank"
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon"
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
      onClick={() => setCursorType('')}
      title={title && title}
    >
      <Icon
        className={clsx(item.className, classes.socialSvg)}
        name={item.name}
        size={item.size}
      />
    </StyledLink>
  ) : item.onClick ? (
    <StyledButton
      onClick={item.onClick}
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon-click"
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
    >
      <Icon
        className={clsx(item.className, classes.socialSvg)}
        name={item.name}
        size={item.size}
      />
    </StyledButton>
  ) : (
    <></>
  )
}
