import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'

import React, { ReactElement } from 'react'
import * as ReactIntl from 'react-intl'

import { WrapPageElementNodeArgs } from 'gatsby'

import Layout from '@components/modules/global/layout'
import Metatags from '@components/modules/global/metatags'

import { messages, defaultLang } from '../translations'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import TrackingProvider from '@system/providers/trackingProvider'
import ToggleComponentProvider from '@system/providers/toggleComponentProvider'
import GlobalTextProvider from '@system/providers/globalTextProvider'
import ModuleThemeProvider from '@system/providers/moduleThemeProvider'
import CustomCursorProvider from '@system/providers/customCursorProvider'

import createTheme from '../../../config/theme'

const muitheme = createTheme()

type WrapPageArgs = WrapPageElementNodeArgs & {
  props: {
    pageContext: DBN.PageHelpers.PageContext
    location: Record<string, boolean>
  }
}

export function wrapPage({ element, props }: WrapPageArgs): ReactElement {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <TrackingProvider {...props}>
          <ModuleThemeProvider {...props}>
            <ToggleComponentProvider {...props}>
              <CustomCursorProvider {...props}>
                <GlobalTextProvider {...props}>
                  <>
                    {props.pageContext?.page?.metaTags && (
                      <Metatags
                        locale={props.pageContext.locale}
                        meta={props.pageContext.page.metaTags}
                      />
                    )}
                    <CssBaseline />
                    <Layout {...props}>{element}</Layout>
                  </>
                </GlobalTextProvider>
              </CustomCursorProvider>
            </ToggleComponentProvider>
          </ModuleThemeProvider>
        </TrackingProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
