import React, { ReactElement } from 'react'
const ArrowRight = (): ReactElement => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 70C54.7777 70 70 54.7777 70 36C70 17.2223 54.7777 2 36 2C17.2223 2 2 17.2223 2 36C2 54.7777 17.2223 70 36 70ZM36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72Z"
        fill="#FF2001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5858 35L35.2929 30.7071L36.7071 29.2929L43.4142 36L36.7071 42.7071L35.2929 41.2929L39.5858 37H30V35H39.5858Z"
        fill="#FF2001"
      />
    </svg>
  )
}

export default ArrowRight
