import React, { ReactElement, createContext, useState, useEffect } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'

export type ModuleThemeProviderProps = DBN.IReactDefaultProps

export interface IModuleThemeContext {
  currentTheme: string
  changeTheme: (newTheme: string) => void
}

export const ModuleThemeContext = createContext<IModuleThemeContext>({
  currentTheme: '',
  changeTheme: () => {
    return null
  },
})

export default function ModuleThemeProvider({
  children,
}: ModuleThemeProviderProps): ReactElement {
  const [currentTheme, setCurrentTheme] = useState('light')
  const location = useLocation()

  useEffect(() => {
    if (!currentTheme) setCurrentTheme('light')
  }, [location.pathname])

  function changeTheme(newTheme: string): void {
    if (newTheme !== currentTheme) {
      setCurrentTheme(newTheme)
    }
  }

  return (
    <ModuleThemeContext.Provider value={{ currentTheme, changeTheme }}>
      {children}
    </ModuleThemeContext.Provider>
  )
}
